.test_app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.poster {
  width: 400px;
  height: 600px;
  background-image: url("./bg.png");
  background-size: cover;
  background-position: center;
  transform-origin: top;
  cursor: pointer;
}

.rolled-up {
  animation: roll-up 1.5s forwards;
}

.rolled-down {
  animation: unroll-down 1.5s forwards;
}

@keyframes roll-up {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@keyframes unroll-down {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.toggle-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
